import { useParams } from "react-router-dom";
import styled from "styled-components";

const PrivacyTitle = styled.h1`
  font-size: 40px;
  font-family: "p22-mackinac-pro", serif;
  font-weight: 700;
  color: #114b5f;
  color: color(display-p3 0.067 0.294 0.373);
`;

const PolicyContainer = styled.div`
  padding: 40px;
`;

const WordeoPrivacyPolicy = () => (
  <div>
    <PrivacyTitle>Wordeo Privacy Policy</PrivacyTitle>
    <p>Last updated: May 26th, 2023</p>
    <p>When you use Wordeo, data are collected for the following purposes.</p>
    <p>
      <b>iCloud</b>
    </p>
    <p>
      Wordeo uses Apple CloudKit to synchronise your settings between your other
      Apple devices. Your data are stored in a private database, which is
      encrypted with a key unique to each user. RooHoo does not have access to
      any data stored and transmitted in this way, nor are we able to track your
      identity or activity between services based on your usage of Wordeo.
    </p>
    <p>
      <b>Advertising</b>
    </p>
    <p>
      Wordeo may deliver ads from Google's AdMob service, which may be
      personalised based on your interests and activity, based on a unique
      advertising identifier. Personalised advertising is only provided if you
      consent to this when you first run Wordeo, and can later be disabled from
      your device settings. You can also{" "}
      <a
        href="https://support.google.com/My-Ad-Center-Help/answer/12155764?hl=en-GB&visit_id=21683968700111-6253883452305723190&rd=1"
        target="_blank"
        rel="noreferrer"
      >
        opt-out of personalised ads
      </a>
      . See{" "}
      <a href="https://support.google.com/admob/answer/6128543?hl=en-G">
        Google's AdMob privacy policy
      </a>{" "}
      for more information on how Google uses your information.
    </p>
    <p>
      More information about how Google uses information from apps that use
      their services{" "}
      <a
        href="https://policies.google.com/technologies/partner-sites"
        target="_blank"
        rel="noreferrer"
      >
        is available here
      </a>
    </p>
  </div>
);

const DayzenPrivacyPolicy = () => (
  <div>
    <PrivacyTitle>Dayzen Privacy Policy</PrivacyTitle>
    <p>Last updated: June 5th, 2023</p>
    <p>When you use Dayzen, data are collected for the following purposes.</p>
    <p>
      <b>iCloud</b>
    </p>
    <p>
      Dayzen uses Apple CloudKit to synchronise your settings between your other
      Apple devices. Your data are stored in a private database, which is
      encrypted with a key unique to each user. RooHoo does not have access to
      any data stored and transmitted in this way, nor are we able to track your
      identity or activity between services based on your usage of Dayzen.
    </p>
    <p>
      <b>Advertising</b>
    </p>
    <p>
      Dayzen may deliver ads from Google's AdMob service, which may be
      personalised based on your interests and activity, based on a unique
      advertising identifier. Personalised advertising is only provided if you
      consent to this when you first run Dayzen, and can later be disabled from
      your device settings. You can also{" "}
      <a
        href="https://support.google.com/My-Ad-Center-Help/answer/12155764?hl=en-GB&visit_id=21683968700111-6253883452305723190&rd=1"
        target="_blank"
        rel="noreferrer"
      >
        opt-out of personalised ads
      </a>
      . See{" "}
      <a href="https://support.google.com/admob/answer/6128543?hl=en-G">
        Google's AdMob privacy policy
      </a>{" "}
      for more information on how Google uses your information.
    </p>
    <p>
      More information about how Google uses information from apps that use
      their services{" "}
      <a
        href="https://policies.google.com/technologies/partner-sites"
        target="_blank"
        rel="noreferrer"
      >
        is available here.
      </a>
    </p>
  </div>
);

const MixaryPrivacyPolicy = () => (
  <div>
    <PrivacyTitle>Mixary Privacy Policy</PrivacyTitle>
    <p>Last updated: July 15th, 2023</p>
    <p>When you use Mixary, data are collected for the following purposes.</p>
    <p>
      <b>iCloud</b>
    </p>
    <p>
    Mixary uses Apple CloudKit to synchronise your settings between your other
      Apple devices. Your data are stored in a private database, which is
      encrypted with a key unique to each user. RooHoo does not have access to
      any data stored and transmitted in this way, nor are we able to track your
      identity or activity between services based on your usage of Mixary.
    </p>
    <p>
      <b>Advertising</b>
    </p>
    <p>
    Mixary may deliver ads from Google's AdMob service, which may be
      personalised based on your interests and activity, based on a unique
      advertising identifier. Personalised advertising is only provided if you
      consent to this when you first run Mixary, and can later be disabled from
      your device settings. You can also{" "}
      <a
        href="https://support.google.com/My-Ad-Center-Help/answer/12155764?hl=en-GB&visit_id=21683968700111-6253883452305723190&rd=1"
        target="_blank"
        rel="noreferrer"
      >
        opt-out of personalised ads
      </a>
      . See{" "}
      <a href="https://support.google.com/admob/answer/6128543?hl=en-G">
        Google's AdMob privacy policy
      </a>{" "}
      for more information on how Google uses your information.
    </p>
    <p>
      More information about how Google uses information from apps that use
      their services{" "}
      <a
        href="https://policies.google.com/technologies/partner-sites"
        target="_blank"
        rel="noreferrer"
      >
        is available here.
      </a>
    </p>
  </div>
);


const JourzaPrivacyPolicy = () => (
  <div>
    <PrivacyTitle>Jourza Privacy Policy</PrivacyTitle>
    <p>Last updated: May 25th, 2023</p>
    <p>When you use Jourza, data are collected for the following purposes.</p>
    <p>
      <b>iCloud</b>
    </p>
    <p>
      Jourza uses Apple CloudKit to synchronise your settings between your other
      Apple devices. Your data are stored in a private database, which is
      encrypted with a key unique to each user. RooHoo does not have access to
      any data stored and transmitted in this way, nor are we able to track your
      identity or activity between services based on your usage of Jourza.
    </p>
    <p>
      <b>Advertising</b>
    </p>
    <p>
      Jourza may deliver ads from Google's AdMob service, which may be
      personalised based on your interests and activity, based on a unique
      advertising identifier. Personalised advertising is only provided if you
      consent to this when you first run Jourza, and can later be disabled from
      your device settings. You can also{" "}
      <a
        href="https://support.google.com/My-Ad-Center-Help/answer/12155764?hl=en-GB&visit_id=21683968700111-6253883452305723190&rd=1"
        target="_blank"
        rel="noreferrer"
      >
        opt-out of personalised ads
      </a>
      . See{" "}
      <a href="https://support.google.com/admob/answer/6128543?hl=en-G">
        Google's AdMob privacy policy
      </a>{" "}
      for more information on how Google uses your information.
    </p>
    <p>
      More information about how Google uses information from apps that use
      their services{" "}
      <a
        href="https://policies.google.com/technologies/partner-sites"
        target="_blank"
        rel="noreferrer"
      >
        is available here
      </a>
    </p>
  </div>
);

const ArliPrivacyPolicy = () => (
  <div>
    <PrivacyTitle>Arli Privacy Policy</PrivacyTitle>
    <p>Last updated: May 13th, 2023</p>
    <p>When you use Arli, data are collected for the following purposes.</p>
    <p>
      <b>iCloud</b>
    </p>
    <p>
      Arli uses Apple CloudKit to synchronise your settings between your other
      Apple devices. Your data are stored in a private database, which is
      encrypted with a key unique to each user. RooHoo does not have access to
      any data stored and transmitted in this way, nor are we able to track your
      identity or activity between services based on your usage of Arli.
    </p>
    <p>
      <b>Advertising</b>
    </p>
    <p>
      Arli may deliver ads from Google's AdMob service, which may be
      personalised based on your interests and activity, based on a unique
      advertising identifier. Personalised advertising is only provided if you
      consent to this when you first run Arli, and can later be disabled from
      your device settings. You can also{" "}
      <a
        href="https://support.google.com/My-Ad-Center-Help/answer/12155764?hl=en-GB&visit_id=21683968700111-6253883452305723190&rd=1"
        target="_blank"
        rel="noreferrer"
      >
        opt-out of personalised ads
      </a>
      . See{" "}
      <a href="https://support.google.com/admob/answer/6128543?hl=en-G">
        Google's AdMob privacy policy
      </a>{" "}
      for more information on how Google uses your information.
    </p>
    <p>
      More information about how Google uses information from apps that use
      their services{" "}
      <a
        href="https://policies.google.com/technologies/partner-sites"
        target="_blank"
        rel="noreferrer"
      >
        is available here
      </a>
    </p>
  </div>
);

const productPrivacyPolicies = {
  jourza: <JourzaPrivacyPolicy />,
  arli: <ArliPrivacyPolicy />,
  wordeo: <WordeoPrivacyPolicy />,
  dayzen: <DayzenPrivacyPolicy />,
  mixary: <MixaryPrivacyPolicy />
};
const PrivacyPolicy = () => {
  let { product } = useParams();

  return (
    <PolicyContainer>
      {productPrivacyPolicies[product] ||
        `Could not find a privacy policy for $
      {product}.`}
    </PolicyContainer>
  );
};
export default PrivacyPolicy;
