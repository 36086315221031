import styled from "styled-components";
import ArliHero from "./HeroUnits/Arli";
import JourzaHero from "./HeroUnits/JourzaHero";

const HeroUnit = styled.div`
  height: 512px;
  box-shadow: 5px 16px 31px rgba(0, 0, 0, 0.49);
`;

const activeHero = <JourzaHero />;

const Hero = () => <HeroUnit>{activeHero}</HeroUnit>;

export default Hero;
