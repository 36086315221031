import styled from "styled-components";
import jourzaScreen from "./jourza-screen.png";
import jourzaJewel from "./jourza-jewel.png";
import appStoreBadge from "./appstore-badge.png";

const JourzaHeroContent = styled.div`
  background: linear-gradient(#1c3a7e, #8293b9);
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const JourzaScreenshot = styled.img`
  width: 380px;
  right: 100px;
  top: 22px;
  position: absolute;

  @media (max-width: 1300px) {
    right: -20px;
  }

  @media (max-width: 1800px) {
    width: 30%;
  }

  @media (max-width: 900px) {
    width: 40%;
  }
  z-index: 1;
`;

const JourzaJewel = styled.img`
  width: 30%;
  position: absolute;
  top: 140px;
  left: 110px;
  z-index: 2;
  @media (max-width: 1800px) {
    left: 20px;
  }
  @media (max-width: 1300px) {
    width: 60%;
    /* left: 20px; */
    top: 20px;
  }
`;

const PromoText = styled.h1`
  color: #fff;
  font-size: 42px;
  position: absolute;
  top: 140px;
  left: 40%;
  font-family: "poppins";
  font-weight: 500;
  width: 30%;
  line-height: 3.7rem;
  @media (max-width: 1800px) {
    width: 60%;
    left: 20px;
    top: 250px;
  }
  @media (max-width: 1300px) {
    width: 60%;
    top: 180px;
  }
  @media (max-width: 900px) {
    top: 80px;
  }
`;

const AppStoreBadgeLink = styled.a`
  position: absolute;
  left: 40%;
  bottom: 140px;
  z-index: 10;
  width: 215px;
  @media (max-width: 1800px) {
    left: 20px;
    bottom: 40px;
  }
  @media (max-width: 1300px) {
    left: 20px;
    bottom: 60px;
  }
  @media (max-width: 900px) {
    bottom: 20px;
  }
`;

const AppStoreBadge = styled.img`
  width: 215px;
`;

const JourzaHero = () => (
  <JourzaHeroContent>
    <JourzaJewel src={jourzaJewel} alt="Jourza" />
    <JourzaScreenshot
      src={jourzaScreen}
      alt="Screenshot of the Jourza app for generating flight plans, showing an example flight plan between two random locations in Australia and Papua New Guinea"
    />
    <PromoText>Infinite flight plans for flight simulators</PromoText>
    <AppStoreBadgeLink
      target="_blank"
      href="https://apps.apple.com/us/app/jourza/id1630377412?uo=2"
    >
      <AppStoreBadge src={appStoreBadge}></AppStoreBadge>
    </AppStoreBadgeLink>
  </JourzaHeroContent>
);

export default JourzaHero;
