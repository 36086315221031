import styled from "styled-components";
import knockoutLogo from "./roohooknockout.png";

const SidebarContainer = styled.div`
  background-color: #114b5f;
  background-color: color(display-p3 0.067 0.294 0.373);
  grid-area: sidebar;
`;

const RooHooLogo = styled.img`
  width: 140px;
  margin: 35px 40px;

  @media (max-width: 600px) {
    transform: rotate(-90deg) translateY(-115px) translateX(-180px);
    margin: 0;
    width: 320px;
  }
  transition: transform 0.6s ease-in-out, width 0.4s ease-in-out;
`;

const Sidebar = () => {
  return (
    <SidebarContainer>
      <a href="/"><RooHooLogo src={knockoutLogo} alt="RooHoo" /></a>
    </SidebarContainer>
  );
};

export default Sidebar;
