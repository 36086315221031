import styled from "styled-components";
import {Link} from "react-router-dom";

const RecentArticlesContainer = styled.div`
  margin-top: 4rem;
`;

const ArticlesRail = styled.div`
 display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 460px));
  gap: 10px;
`;

const ArticleTileContainer = styled.div`
    border-left: 6px solid #114b5f;
    padding: 0.5rem 1rem;
    background-color: rgba(0,0,0,0.1);
    border-radius: 5px;
  p {
    font-family: sans-serif;
    font-weight: normal;
    color: #444;
  }

  h2 {
    font-family: sans-serif;
    font-size: 1.5rem;
  }
`;

const ArticleTile = ({slug, headline, standfirst}) => (
    <Link to={`/article/${slug}`}>
    <ArticleTileContainer>
        <h2>{headline}</h2>
        <p>{standfirst}</p>
    </ArticleTileContainer>
    </Link>
)
const RecentArticles = () => (
    <RecentArticlesContainer>
    <h1>Latest articles</h1>
    
    <ArticlesRail>
    <ArticleTile slug="dayzen-reschedule-groups" headline="Introducing Reschedule Groups to Dayzen" standfirst="Group tasks together for Dayzen to reschedule" />
    <ArticleTile slug="mixary-made-for-you" headline="Mixary adds personalized Made For You mixes" standfirst="Generate personalized playlists to complement your tastes" />
    <ArticleTile slug="mixary-unique" headline="Mixary: Unique Mixes for Apple Music" standfirst="Generate unique mixes tailor-made for you, and then share them with friends" />
    <ArticleTile slug="check-in-with-dayzen" headline="Check-in with Dayzen to improve your schedule" standfirst="Check-ins help Dayzen learn how to better plan your day" />
    <ArticleTile slug="dayzen-new-way" headline="Dayzen: The new way to manage your daily schedule" standfirst="Dayzen plans your day by thinking in terms of which tasks you find energising and draining" />
    </ArticlesRail>
    </RecentArticlesContainer>
)

export default RecentArticles;