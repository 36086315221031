import styled from "styled-components";
import "./App.css";
import Sidebar from "./Components/Sidebar";
import MainPanel from "./Components/MainPanel";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PrivacyPolicy from "./Routes/PrivacyPolicy";
import Article from "./Routes/Article";

const SiteContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;

  @media (max-width: 600px) {
    grid-template-columns: 100px 3fr;
    grid-template-areas: "sidebar main";
  }

  @media (min-width: 601px) {
    grid-template-columns: 220px 3fr;
    grid-template-areas: "sidebar main";
  }
`;

const MainContent = styled.div`
  background-color: #f5dfbb;
  grid-area: main;

  font-size: 21px;
  font-family: sans-serif;
  /* font-family: "p22-mackinac-pro", serif; */
  /* font-weight: 700; */
  line-height: 24px;
  font-style: normal;
  color: #562c2c;
  color: color(display-p3 0.337 0.173 0.173);

  a {
    color: #562c2c;
    color: color(display-p3 0.337 0.173 0.173);
    font-weight: bold;
    text-decoration: none;
  }

  a:hover {
    color: #114b5f;
    color: color(display-p3 0.067 0.294 0.373);
    text-decoration: underline;
  }

  h1 {
    color: #114b5f;
    color: color(display-p3 0.067 0.294 0.373);
  }

`;

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <MainContent>
        <MainPanel />
      </MainContent>
    ),
  },
  {
    path: "article/:articleId",
    element: (
      <MainContent>
        <Article />
      </MainContent>
    )
  },
  {
    path: "privacy/:product",
    element: (
      <MainContent>
        <PrivacyPolicy />
      </MainContent>
    ),
  },
]);

function App() {
  return (
    <div className="App">
      <SiteContainer>
        <Sidebar />
        {/* <MainPanel></MainPanel> */}
        <RouterProvider router={router} />
      </SiteContainer>
    </div>
  );
}

export default App;
