import styled from "styled-components";
import ProductTile from "./ProductTiles/ProductTile";

const ProductListTitle = styled.h1`
  font-size: 40px;
  font-family: "p22-mackinac-pro", serif;
  line-height: 2rem;
  font-weight: 700;
  color: #114b5f;
  color: color(display-p3 0.067 0.294 0.373);
`;

const TileGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 460px));
  gap: 10px;
`;

const ProductList = () => (
  <>
    <ProductListTitle>Our products</ProductListTitle>
    <TileGrid>
      <ProductTile product="jourza" />
      <ProductTile product="arli" />
      <ProductTile product="dayzen" />
      <ProductTile product="mixary" />
    </TileGrid>
  </>
);

export default ProductList;
