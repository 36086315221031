import {useEffect, useState} from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

const ArticleContainer = styled.div`
    padding: 40px;
`

const availableArticles = ["dayzen-new-way", "check-in-with-dayzen", "mixary-unique", "mixary-made-for-you", "dayzen-reschedule-groups"];

const Article = () => {
    let {articleId} = useParams();

    let[htmlFileString, setHtmlFileString] = useState();

    async function fetchHtml() {
        console.log("article name", articleId)
    if(availableArticles.includes(articleId)) {
      setHtmlFileString(await (await fetch(`/Content/${articleId}/index.html`)).text());
    }
    else {
        setHtmlFileString("<p>Sorry, could not find this article.</p>")
    }
    }

    useEffect(() => {
      fetchHtml();
    }, []);

    return (
    <ArticleContainer>
        <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div>
    </ArticleContainer>
)}

export default Article;