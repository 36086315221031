import styled from "styled-components";
import ProductList from "./ProductList";
import RecentArticles from "./RecentArticles";
import Hero from "./Hero";

const MainPanelContainer = styled.div`
  /* background-color: #f5dfbb;
  grid-area: main; */
`;

const Body = styled.div`
  font-size: 21px;
  font-family: "p22-mackinac-pro", serif;
  font-weight: 700;
  line-height: 24px;
  font-style: normal;
  padding: 80px 40px;
`;

const BodyText = styled.p`
  color: #562c2c;
  color: color(display-p3 0.337 0.173 0.173);
`;

const HomePageContent = () => (
  <Body>
    <BodyText>
      Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
      dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
      proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
      doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
      inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
    </BodyText>
  </Body>
);

const SupportContainer = styled.div`
  p {
    font-family: sans-serif;
    font-weight: normal;
    color: #444;
  }
`;

const SupportList = () => (
  <SupportContainer>
    <h2>Support</h2>
    <p>Get help with RooHoo products, including Dayzen, Arli, Mixary and Jourza at the <a href="https://roohoo.freshdesk.com/support/home" target="_blank">RooHoo Helpdesk.</a></p>
  </SupportContainer>
);

const MainPanel = () => {
  return (
    <MainPanelContainer>
      <Hero />
      <Body>
        <ProductList />
        <RecentArticles />
        <SupportList />
      </Body>
    </MainPanelContainer>
  );
};

export default MainPanel;
