import styled from "styled-components";
import arliIcon from "./arli.png";
import arliName from "./arli-name.png";

const ArliHeroContent = styled.div`
  background: linear-gradient(#2b304e, #1c2033);
  height: 100%;
  position: relative;
`;

const ArliIcon = styled.img`
  width: 512px;
  position: absolute;
`;

const ArliHeader = styled.img`
  position: absolute;
  width: 256px;
  top: 20%;
  left: 20%;
`;

const ArliHero = () => (
  <ArliHeroContent>
    <ArliIcon src={arliIcon} />
    <ArliHeader src={arliName} />
  </ArliHeroContent>
);

export default ArliHero;
