import styled from "styled-components";
import jourzaTile from "./jourzaTile.png";
import arliTile from "./arliTile.png";
import wordeoTile from "./template-wordeo.png";
import dayzenTile from "./template-dayzen.png";
import mixaryTile from "./template-mixary.png"

const productTiles = {
  jourza: jourzaTile,
  arli: arliTile,
  wordeo: wordeoTile,
  dayzen: dayzenTile,
  mixary: mixaryTile
};

const productSupportLinks = {
  jourza: "https://roohoo.freshdesk.com/support/solutions/103000136148",
  arli: "https://roohoo.freshdesk.com/support/solutions/103000139499",
  wordeo: "https://roohoo.freshdesk.com/support/home",
  dayzen: "https://roohoo.freshdesk.com/support/home",
  mixary: "https://roohoo.freshdesk.com/support/home"
};

const appStoreLinks = {
  jourza: "https://apps.apple.com/us/app/jourza/id1630377412",
  arli: "https://apps.apple.com/us/app/arli-endless-random-numbers/id1635548739",
  dayzen: "https://apps.apple.com/us/app/dayzen/id1631585161",
  mixary: "https://apps.apple.com/gb/app/mixary/id1673440317"
}

const Tile = styled.div`
  border-radius: 5px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  width: 460px;
  aspect-ratio: 460/210;
  /* height: 210px; */

  @media (max-width: 1000px) {
    width: 100%;
   
  }
`;

const ProductTile = ({ product }) => {
  return (
    <a href={appStoreLinks[product]} target="_blank" rel="noreferrer">
      <Tile image={productTiles[product]}></Tile>
    </a>
  );
};

export default ProductTile;
